import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import {
  Snackbar
} from '@mui/material';

const MastodonCallback = () => {
    const { domain_name } = useParams();
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const urlToken = query.get('code');
    const navigate = useNavigate();
    const [errorOccured, setErrorOccured] = useState(false);
    const [token, setToken] = useState<string | null>(null);

    useEffect(() => {
        const fetchAccessToken = async () => {
            try {
                const response = await fetch(`/api/v1/mastodon/callback/${domain_name}?code=${urlToken}`);
                const data = await response.json();
                if (response.status === 400 || response.status === 500) {
                    setErrorOccured(true);
                } else {
                    // Cookies.set('jwtToken', data.access_token, { secure: true, sameSite: 'strict', expires: 7 });
                    // Cookies.set('jwtToken', data.access_token, { sameSite: 'strict', expires: 7 });
                    // setToken(data.access_token);
                    navigate("/mypage"); // ログインが完了したら/mypageにリダイレクト
                }
            } catch (error) {
                console.error('Error fetching access token:', error);
            }
        };

        if (domain_name && urlToken) {
            fetchAccessToken();
        } else {
            console.error('Callback code or domain name not found');
        }
    }, [domain_name, urlToken, navigate]);

    const handleCloseSnackbar = () => {
      setErrorOccured(false);
    };

    return (
        <div>
            {token ? (
                <div>
                    <p>Login Success. Redirecting...{token}</p>
                </div>
            ) : (
                <p>Fetching access token...</p>
            )}
            <Snackbar
              open={errorOccured}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              message='接続に問題が発生しました。もう一度ログインしてください。'
            />
        </div>
    );
}

export default MastodonCallback;
