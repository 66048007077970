import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import {
  TextField,
  Button,
  Grid,
  Avatar,
  Typography,
  Switch,
  List,
  FormControlLabel,
  FormGroup,
  Card,
  CardContent,
  Snackbar,
  MenuItem,
  Stack,
  Modal,
  Box,
  useMediaQuery,
  ButtonGroup,
  IconButton,
  Alert,
  Chip,
  FormControl,
  InputLabel,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import Select, { SelectChangeEvent } from '@mui/material/Select';

import LogoutIcon from '@mui/icons-material/Logout';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SettingsIcon from '@mui/icons-material/Settings';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';

interface UserInfo {
  user_id: string;
  display_name: string;
  profile_img: string;
  server: string;
  accepting: boolean;
  error: string;
  app: string;
  notify: boolean;
  reject_sensitive_word: boolean;
  loginonly: boolean;
}

interface QuestionData {
  id: number;
  server: string;
  user_id: string;
  question: string;
  nsfw: boolean;
  created_at: Date;
  questioner_user_id: string;
  questioner_server: string;
  answer: string;
  answerd_at: Date;
  not_anonymous: boolean;
}

interface QuestionCount {
  count: number;
}

enum VisibilityOptions {
  PUBLIC = 'public',
  UNLISTED = 'unlisted',
  PRIVATE = 'private',
}

interface AnswerState {
  [questionId: number]: {
    answer: string;
    characterCount: number;
    error: string | null;
    nsfw: boolean;
    reply: boolean;
    visibility: VisibilityOptions;
  };
}

const MyPage = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const navigate = useNavigate();
  const token = Cookies.get('jwtToken');
  const [questionData, setQuestionData] = useState<QuestionData[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const csrftoken = Cookies.get('csrftoken');
  const [answerSubmitted, setAnswerSubmitted] = useState(false);
  const [answerFailed, setAnswerFailed] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [deleteQuestiuonId, setDeleteQuestiuonId] = useState<number>(0);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);
  const [settingModalOpen, setSettingModalOpen] = useState(false);
  const [questionCount, setQuestionCount] = useState<number>(0);
  const [viewLevel, setViewLevel] = React.useState('not_answerd');

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const jwtToken = Cookies.get('jwtToken');

  const params = {view_level : viewLevel};
  const query = new URLSearchParams(params);

  const handlePageChange = async (newPage: number): Promise<void> => {
    try {
      const questionResponse = await fetch(`/api/v1/questions/${newPage}?` + query, {
        method: 'GET',
      });
      const jsonData: QuestionData[] = await questionResponse.json();
      setQuestionData(jsonData);
      setCurrentPage(newPage);
    } catch (error) {
      console.error('Error fetching questions:', error);
      setError('Failed to fetch questions');
    }
  };

  const handleCloseSnackbar = () => {
    setAnswerSubmitted(false);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleCloseSettingModal = () => {
    setSettingModalOpen(false);
  };

  const UTCtoJST = (utcDate: Date): string => {
    const jstDate = moment.utc(utcDate).tz('Asia/Tokyo');
    const formattedDate = jstDate.format('YYYY-MM-DD HH:mm:ss');
    return formattedDate;
  };

  const [answers, setAnswers] = useState<AnswerState>({});

  const handleAnswerChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    questionId: number
  ) => {
    const answer = event.target.value;
    const characterCount = answer.length;

    const updatedAnswers = {
      ...answers,
      [questionId]: {
        answer,
        characterCount,
        error: characterCount === 0 || characterCount > 200 ? '入力は1文字以上かつ200文字以下である必要があります' : null,
        nsfw: answers[questionId]?.nsfw || false,
        reply: answers[questionId]?.reply || false,
        visibility: answers[questionId]?.visibility || VisibilityOptions.PUBLIC, // デフォルトで公開を設定する
      },
    };
    setAnswers(updatedAnswers);
  };

  const handleNsfwToggle = (event: React.ChangeEvent<HTMLInputElement>, questionId: number) => {
    const updatedAnswers = {
      ...answers,
      [questionId]: {
        ...answers[questionId],
        nsfw: event.target.checked,
      },
    };
    setAnswers(updatedAnswers);
  };

  const handleReplyToggle = (event: React.ChangeEvent<HTMLInputElement>, questionId: number) => {
    const updatedAnswers = {
      ...answers,
      [questionId]: {
        ...answers[questionId],
        reply: event.target.checked,
      },
    };
    setAnswers(updatedAnswers);
  };

  const handleVisibilityChange = (
    event: any, // any型として扱う
    questionId: number
  ) => {
    const visibility = event.target.value as VisibilityOptions;
    const updatedAnswers = {
      ...answers,
      [questionId]: {
        ...(answers[questionId] || {}),
        visibility,
      },
    };
    setAnswers(updatedAnswers);
  };  
  
  const handleDeleteClick = async (questionId: number) => {
    setDeleteQuestiuonId(questionId)
    setDeleteModalOpen(true);
  };

  const deleteQuestiuon = async (questionId: number) => {
    const headers = {
      'X-CSRF-Token': `${csrftoken}`,
    };
    try {
      const deleteResponse = await fetch(`/api/v1/delete/${questionId}`,
      {
        method: 'DELETE',
        headers: headers,
      },
      );
      const jsonData: QuestionData[] = await deleteResponse.json();
    } catch (error) {
      // エラーハンドリング
      console.log(error);
    }
    fetchData();
    setDeleteModalOpen(false);
    setDeleteSubmitted(true);
  }

  const handleSettingClick = () => {
    setSettingModalOpen(true);
  };

  const enterPost = (event: React.KeyboardEvent<HTMLElement>, id: number) => {
    if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
      handleSubmitClick(id);
    }
  };

  const handleSubmitClick = async (questionId: number) => {
    const { answer, characterCount, nsfw, reply, visibility } = answers[questionId];
    const body = {
      answer: answer,
      nsfw: nsfw,
      reply: reply,
      visibility: visibility,
    };
    const headers = {
      'X-CSRF-Token': `${csrftoken}`,
      'Content-Type': 'application/json',
    };
    try {
      const questionResponse = await fetch(`/api/v1/answer/${questionId}`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: headers,
      },
      );
      const jsonData: QuestionData[] = await questionResponse.json();
      if (!questionResponse.ok) {
        if (questionResponse.status === 400) {
          setAnswerFailed(true);
        } else {
          console.error('Request failed with status:', questionResponse.status);
          const errorData = await questionResponse.json();
          setErrorMessage(errorData.error);
        }
      } else {
        const data = await questionResponse.json();
        setUserInfo(data);
      }
    } catch (error) {
      console.log(error);
    }

    const updatedAnswers = {
      ...answers,
      [questionId]: {
        answer: '',
        characterCount: 0,
        error: null,
        nsfw: false,
        reply: false,
        visibility: VisibilityOptions.PUBLIC,
      },
    };
    setAnswerSubmitted(true);
    setAnswers(updatedAnswers);
    fetchData();
  };

  const fetchData = async () => {
    try {
      const questionResponse = await fetch(`/api/v1/questions/${currentPage}?` + query,
      {
        method: 'GET',
      },
      );
      const jsonData: QuestionData[] = await questionResponse.json();
      setQuestionData(jsonData);
    } catch (error) {
      // エラーハンドリング
      console.log(error);
    }
    try {
      const questionCount = await fetch(`/api/v1/count/questions`,
      {
        method: 'GET',
      },
      );
      const jsonData: QuestionCount = await questionCount.json();
      setQuestionCount(jsonData.count);
    } catch (error) {
      // エラーハンドリング
      console.log(error);
    }
  };

  const handleAcceptingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    const postSetting = async () => {
      const headers = {
        'X-CSRF-Token': `${csrftoken}`,
      };
      try {
        const settingResponse = await fetch(`/api/v1/question/public/${newValue}`,
        {
          method: 'POST',
          headers: headers,
        },
        );
        const responseData = await settingResponse.json();
      } catch (error) {
        console.log(error);
      }
    }
    postSetting();
    fetchUserInfo();
  };

  const handleNotifyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    const notifySetting = async () => {
      const headers = {
        'X-CSRF-Token': `${csrftoken}`,
      };
      try {
        const settingResponse = await fetch(`/api/v1/question/notify/${newValue}`,
        {
          method: 'POST',
          headers: headers,
        },
        );
        const responseData = await settingResponse.json();
      } catch (error) {
        console.log(error);
      }
    }
    notifySetting();
    fetchUserInfo();
  };

  const handleSensitiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    const notifySetting = async () => {
      const headers = {
        'X-CSRF-Token': `${csrftoken}`,
      };
      try {
        const settingResponse = await fetch(`/api/v1/question/reject/${newValue}`,
        {
          method: 'POST',
          headers: headers,
        },
        );
        const responseData = await settingResponse.json();
      } catch (error) {
        console.log(error);
      }
    }
    notifySetting();
    fetchUserInfo();
  };

  const handleLoginonly = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    const notifySetting = async () => {
      const headers = {
        'X-CSRF-Token': `${csrftoken}`,
      };
      try {
        const settingResponse = await fetch(`/api/v1/question/loginonly/${newValue}`,
        {
          method: 'POST',
          headers: headers,
        },
        );
        const responseData = await settingResponse.json();
      } catch (error) {
        console.log(error);
      }
    }
    notifySetting();
    fetchUserInfo();
  };

  const handleShareClick = (server: string, user_id: string) => {
    var uri = new URL(window.location.href);
    var newTabUrl = "https://" + server + "/share?text=FediQBで質問を募集しています！%23fediQB%0D%0Ahttps://" + uri.hostname + "/questionbox/@" + user_id + "@" + server;
    
    var newTab = window.open(newTabUrl, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      // If the new tab was blocked by the browser, fall back to changing the current window's location
      window.location.href = newTabUrl;
    }
  };
  
  const fetchUserInfo = async () => {
    if (jwtToken == null) {
      navigate("/login");
    }
    const headers = {
      'X-CSRF-Token': `${csrftoken}`,
    };
    try {
      const response = await fetch(
        `/api/v1/profile`,
        {
          method: 'GET',
          headers: headers,
        },
      );

      if (!response.ok) {
        if (response.status === 401) {
          navigate("/login");
          Cookies.remove('jwtToken');
        } else {
          console.error('Request failed with status:', response.status);
          const errorData = await response.json();
          setErrorMessage(errorData.error);
        }
      } else {
        const data = await response.json();
        setUserInfo(data);
      }
    } catch (error) {
      navigate("/login");
    }
  };

  const handleViewLevel = (event: SelectChangeEvent) => {
    setViewLevel(event.target.value);
  };

  useEffect(() => {
    const token = Cookies.get('jwtToken');
    handlePageChange(currentPage);
    fetchUserInfo();
    fetchData();
  }, [currentPage, viewLevel]);  
  
  const handleLogout = async () => {
    const headers = {
      'X-CSRF-Token': `${csrftoken}`,
    };
    try{
      const response = await fetch(`/api/v1/logout`,{method:'POST', headers: headers,});
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <div>
      {errorMessage && (<p>{errorMessage}</p>)}
      {userInfo && (
        <div>
          {userInfo.accepting === false &&(
            <Alert severity="info" sx={{mb: 2}}>
              質問の受付をしない設定になっています。「ユーザー設定」から有効にしましょう！！
            </Alert>              
          )}
          <Grid container alignItems='center' justifyContent='center' direction='row' spacing={2} sx={{mt: 1 , mb: 4 }}>
            <Grid item xs={12} sm={8}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Avatar alt={userInfo.display_name} src={userInfo.profile_img} sx={{ width: "100%", height: "auto", maxWidth: 140 }} />              
                </Grid>
                <Grid item xs={12} sm={9} sx={{ textAlign: isMobile ? 'center' : 'left', mt: isMobile ? 0 : 1 }} height={"100%"}>
                  <Typography variant='h5' component='h5' sx={{wordBreak: "break-all"}}>
                    {userInfo.display_name}
                  </Typography>
                  <Typography>
                    さんのマイページ
                  </Typography>
                  <Typography variant='subtitle1' sx={{wordBreak: "break-all"}}>
                    <a href={'https://' + userInfo.server + '/@' + userInfo.user_id}>
                      @{userInfo.user_id}@{userInfo.server}
                    </a>
                  </Typography>
                  {userInfo.accepting && userInfo.loginonly == false && (
                    <Chip label="全員から質問を受付中" size="small" color="success" />
                  )}
                  {userInfo.accepting && userInfo.loginonly == true && (
                    <Chip label="ログイン中ユーザーから質問を受付中" size="small" color="primary" />
                  )}
                  {userInfo.accepting == false && (
                    <Chip label="質問の受付を停止中" size="small" color="error" />
                  )}
                  {userInfo.notify == true && (
                    <Chip sx={{ml:1}} label="DMで通知を受信する" size="small" color="warning" />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <ButtonGroup
                orientation="vertical"
                aria-label="Vertical button group"
                sx={{width: "100%"}}
              >
                {userInfo.accepting && (
                  <Button
                    onClick={() => navigate("/questionbox/@" + userInfo.user_id + "@" + userInfo.server)}
                  >
                    <QuestionAnswerIcon sx={{mr:1}}/>質問ページを開く
                  </Button>
                 )}
                <Button
                  onClick={() => handleSettingClick()}
                >
                  <SettingsIcon sx={{mr:1}}/>ユーザー設定を開く
                </Button>
                {userInfo.accepting && (
                  <Button
                    onClick={() => handleShareClick(userInfo.server,userInfo.user_id)}
                  >
                    <OpenInNewIcon sx={{mr:1}}/> 質問を募集する
                  </Button>
                )}
                <Button onClick={handleLogout}>
                  <LogoutIcon sx={{mr:1}}/> ログアウト
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Modal
            open={settingModalOpen}
            onClose={handleCloseSettingModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                設定
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                質問箱について設定ができます。
              </Typography>
              <FormGroup sx={{ mt: 2 }}>
                <FormControlLabel control={<Switch defaultChecked={userInfo.accepting} onChange={handleAcceptingChange} />} label="質問を受け取る" />
                <FormControlLabel control={<Switch defaultChecked={userInfo.notify} onChange={handleNotifyChange} />} label="質問が来たらDMで通知を受け取る" />
                <FormControlLabel control={<Switch defaultChecked={userInfo.reject_sensitive_word} onChange={handleSensitiveChange} />} label="センシティブワードを含む質問を表示しない" />
                <FormControlLabel control={<Switch defaultChecked={userInfo.loginonly} onChange={handleLoginonly} />} label="ログインしているユーザーからのみ質問を受け取る" />
              </FormGroup>
            </Box>
          </Modal>
        </div>
      )}
      {questionData && (
        <div>
          <Grid container sx={{ mt: 1 }}>
            <FormControl sx={{mr:1, minWidth: 200 }} size="small">
              <InputLabel id="view-select-label">表示項目</InputLabel>
              <Select
                labelId="view-select-label"
                id="view-select"
                value={viewLevel}
                label="表示項目"
                onChange={handleViewLevel}
              >
                <MenuItem value={"not_answerd"}>未回答の質問</MenuItem>
                <MenuItem value={"answerd"}>回答済みの質問</MenuItem>
                <MenuItem value={"all"}>すべての質問</MenuItem>
              </Select>
            </FormControl>
            {viewLevel == "not_answerd" && (
              <Chip label={questionCount} color="primary"/>
            )}
          </Grid>
          {viewLevel == "not_answerd" && questionCount == 0 && (
            <Alert severity="info" sx={{mt: 2, mb: 2}}>
              現在質問がありません。「質問を募集する」から質問を募集しましょう！
            </Alert>              
          )}
          <List>
            {questionData.map((question) => (
              <Card key={question.id} sx={{ minWidth: 275, mt: 1.5 }} >
                <CardContent>
                  <Grid container sx={{ position: 'relative' }}>
                    <Box>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        質問
                      </Typography>
                      <Typography variant="h6" component="div" sx={{ wordBreak: "break-all" }}>
                        {question.question}
                      </Typography>
                      {question.answer != "" && (
                        <Box>
                          <Typography sx={{ mt:1, fontSize: 14 }} color="text.secondary" gutterBottom>
                            回答
                          </Typography>
                          <Typography sx={{ wordBreak: "break-all" }}>
                            {question.answer}
                          </Typography>                          
                        </Box>
                      )}
                      <Grid container sx={{ mt: 1.5 }}>
                        <Typography variant="body2" sx={{ mr: 1.2 }} color="text.secondary">
                          質問者：
                          {question.not_anonymous ? (
                            <a href={'https://' + question.questioner_server + '/@' + question.questioner_user_id}>
                              @{question.questioner_user_id}@{question.questioner_server}
                            </a>
                          ) : (
                            "匿名の質問者"
                          )}
                        </Typography>
                        <Typography variant="body2" sx={{ mr: 1 }} color="text.secondary">
                          質問日時：{UTCtoJST(question.created_at)}
                        </Typography>
                        {answers[question.id]?.error && <Typography sx={{ mt: 1 }} style={{ color: 'red' }}>{answers[question.id]?.error}</Typography>}
                      </Grid>
                    </Box>
                    <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                      <IconButton aria-label="delete" color="error" onClick={() => handleDeleteClick(question.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                  {question.answer == "" && (
                    <Box>
                      <TextField
                        id={`outlined-multiline-static-${question.id}`}
                        label='回答する'
                        fullWidth
                        multiline
                        rows={3}
                        value={answers[question.id]?.answer}
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleAnswerChange(event, question.id)}
                        sx={{ mt: 2 }}
                        onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => enterPost(event, question.id)}
                      />
                      <Stack direction="row" sx={{ mt: 1 }}>
                        <Box>
                          <Grid container>
                            <Typography sx={{ mr:2 , mt: 1 }}>文字数: {answers[question.id]?.characterCount || 0}/200</Typography>
                            {question.not_anonymous ? (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={answers[question.id]?.reply || false}
                                    onChange={(event) => handleReplyToggle(event, question.id)}
                                    name="reply"
                                  />
                                }
                                label="質問者にメンションする"
                              />
                            ):(
                              ""
                            )}

                            {/* NSFWのSwitch */}
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={answers[question.id]?.nsfw || false}
                                  onChange={(event) => handleNsfwToggle(event, question.id)}
                                  name="nsfw"
                                />
                              }
                              label="NSFW"
                            />

                            {/* 公開範囲のSelect */}
                            <Select
                              labelId={`visibility-label-${question.id}`}
                              id={`visibility-select-${question.id}`}
                              value={answers[question.id]?.visibility || VisibilityOptions.PUBLIC}
                              onChange={(event) => handleVisibilityChange(event, question.id)}
                              size="small"
                              sx={{ mr: 1 }}
                            >
                              <MenuItem value={VisibilityOptions.PUBLIC}>公開/パブリック</MenuItem>
                              <MenuItem value={VisibilityOptions.UNLISTED}>未収載/ホーム</MenuItem>
                              <MenuItem value={VisibilityOptions.PRIVATE}>フォロワー限定/フォロワー</MenuItem>
                            </Select>
                          </Grid>                              
                        </Box>
                        <div style={{ flexGrow: 1 }}></div>
                        <Box>
                          <Button
                            variant='contained'
                            sx={{ width: "6rem" }}
                            onClick={() => handleSubmitClick(question.id)}
                            startIcon={<SendIcon />}
                            disabled={answers[question.id]?.characterCount === undefined || answers[question.id]?.characterCount === 0 || answers[question.id]?.characterCount > 200 || !!error}
                          >
                            投稿
                          </Button>                      
                        </Box>

                      </Stack>
                      </Box>
                    
                  )}
                </CardContent>
              </Card>
            ))}
            <Grid container alignItems='center' justifyContent='center' direction="column">
              <Grid>
                <Button sx={{ mt: 1.5 }} variant="contained" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0}>
                  前のページ
                </Button>
                <Button sx={{ mt: 1.5, ml: 1.5 }} variant="contained" onClick={() => handlePageChange(currentPage + 1)} disabled={questionData.length < 20}>
                  次のページ
                </Button>
              </Grid>
            </Grid>
          </List>
        </div>
      )}
      <Snackbar
        open={answerSubmitted}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message='回答が投稿されました！'
      />
      <Snackbar
        open={answerFailed}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message='回答をする際にAPIエラーが発生しました。しばらくしてから再度試してください。'
      />
      <Snackbar
        open={deleteSubmitted}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message='質問が削除されました！'
      />
      <Modal
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            警告
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            本当にこの質問を削除しますか？削除すると元に戻すことはできません。<br />
            誹謗中傷の質問について発信者開示請求などの必要がある場合は削除しないでください。
          </Typography>
          <Button
              variant='contained'
              sx={{ mt: 2, mr: 1 }}
              onClick={() => deleteQuestiuon(deleteQuestiuonId)}
              color="error"
            >
              質問を削除
          </Button>
          <Button
            variant='contained'
            sx={{ mt: 2 }}
            onClick={() => setDeleteModalOpen(false)}
            disabled={!!error}
          >
            削除しない
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default MyPage;