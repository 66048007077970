import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import LoginIcon from '@mui/icons-material/Login';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;

export default function DrawerAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const csrftoken = Cookies.get('csrftoken');

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const token = Cookies.get('jwtToken');

  const handleLogout = async () => {
    const headers = {
      'X-CSRF-Token': `${csrftoken}`,
    };
    try{
      const response = await fetch(`/api/v1/logout`,{method:'POST', headers: headers,});
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogin = () => {
    navigate("/login");
  }

  const handleMypage = () => {
    navigate("/mypage");
  }

  const handleTopPage = () => {
    navigate("/");
  }
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Button onClick={handleTopPage} sx={{ my: 2 }}>
        <img src="/fediqb_banner_dark.svg" alt="FediQB" height={30} />
      </Button>
      <Divider />
      <List>
      {token ? (
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
          <ListItem key="Mypage" disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} onClick={handleMypage}>
              <ListItemText primary="Mypage" />
            </ListItemButton>          
          </ListItem>
          <ListItem key="Logout" disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} onClick={handleLogout}>
              <ListItemText primary="Logout" />
            </ListItemButton>          
          </ListItem>
        </Box>
      ) : (
        <ListItem key="Login" disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} onClick={handleLogin}>
            <ListItemText primary="Login" />
          </ListItemButton>          
        </ListItem>
      )}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" style={{ background: '#eceff1' }}>
        <Container maxWidth="xl">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' }, color: "#263238" }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ mt:1, flexGrow: 1, display: { xs: 'block', sm: 'block' } }}
              onClick={handleTopPage}
            >
              <img src="/fediqb_banner_dark.svg" alt="FediQB" height={40} />
            </Typography>
            {token ? (
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Button sx={{color:"#263238"}} onClick={handleMypage}>
                  <PersonIcon/>マイページ
                </Button>
                <Button sx={{color:"#263238"}} onClick={handleLogout}>
                  <LogoutIcon/>ログアウト
                </Button>
              </Box>
            ) : (
              <Button sx={{color:"#263238"}} onClick={handleLogin}>
                <LoginIcon/>ログイン
              </Button>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}
