import React from 'react';

const Top = () => {
  return (

    <div>
        <h1>404 Page Not Found</h1>
    </div>
  );
};

export default Top;