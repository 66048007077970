import { Route, Routes } from "react-router-dom";
import Login from "./components/login";
import Top from "./components/top";
import MastodonCallback from "./components/mastodon_callback";
import MisskeyCallback from "./components/misskey_callback";
import QuestionBox from "./components/question_form";
import NotFound from "./components/notfound";
import MyPage from "./components/mypage";

export const Router = () => {
    return (
        <Routes>
            <Route path="/" element={<Top/>} />
            <Route path="/login" element={<Login/>}/>
            <Route path="/mypage" element={<MyPage/>}/>
            <Route path="/mastodon/callback/:domain_name" element={<MastodonCallback />} />
            <Route path="/misskey/callback/:domain_name" element={<MisskeyCallback />} />
            <Route path="/questionbox/:user_id" element={<QuestionBox />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default Router;
