import { SET_TOKEN } from './actions';

interface AppState {
  token: string | null;
}

const initialState: AppState = {
  token: null,
};

const appReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
