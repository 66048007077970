import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import Cookies from 'js-cookie';
import Turnstile from './turnstile';

import {
  TextField,
  Button,
  Grid,
  Avatar,
  Typography,
  Snackbar,
  List,
  FormGroup,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
  Alert,
  Badge,
  Stack,
  Box,
} from '@mui/material';

interface UserInfo {
  user_id: string;
  display_name: string;
  avatar: string;
  server: string;
  accepting: boolean;
  error: string;
  app: string;
  loginonly: boolean;
}

interface Answer {
  server: string;
  answer: string;
  id: number;
  answerd_at: Date;
  question: string;
  user_id: string;
  created_at: string;
  nsfw: boolean;
  questioner_user_id: string;
  questioner_server: string;
  not_anonymous: boolean;
}

const QuestionBox = () => {
  const { user_id } = useParams();
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [Question, setQuestion] = useState('');
  const [questionSubmitted, setQuestionSubmitted] = useState(false);
  const [questionSubmitError, setQuestionSubmitError] = useState(false);
  const [questionApiLimitError, setQuestionApiLimitError] = useState(false);
  const [CFTurnstileToken, setCFTurnstileToken] = useState(false);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [notAnonymous, setnotAnonymous] = useState(false);
  const csrftoken = Cookies.get('csrftoken');
  const [nsfwDisplay, setNsfwDisplay] = useState(false);

  const jwtToken = Cookies.get('jwtToken');

  const handleQuestionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuestion(event.target.value);
    if (event.target.value.length === 0 || event.target.value.length > 80) {
      setError('入力は1文字以上かつ80文字以下である必要があります');
    } else {
      setError(null);
    }
  };

  const handleSubmitClick = async () => {
    if (!token) {
      setCFTurnstileToken(true);
      return;
    }

    try {
      const headers = {
        'X-CSRF-Token': `${csrftoken}`,
        'Content-Type': 'application/json',
      };
      const body = {
        question: Question,
        token: token,
        not_anonymous: notAnonymous,
      };
      const response = await fetch(
        `/api/v1/question/${user_id}`,
        {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body),
        },
      );
      const data = await response.json();
      if (!response.ok) {
        if (response.status === 400) {
          setQuestionSubmitError(true);
        } else if (response.status === 429) {
          setQuestionApiLimitError(true);
        } else if (response.status === 401) {
          setCFTurnstileToken(true);
        } else {
          console.error('Request failed with status:', response.status);
        }
      } else {
        setQuestionSubmitted(true);
        setQuestion('');
        fetchAnswers();
      }
    } catch (error) {
      console.error('Error submitting question:', error);
    }
  };

  const fetchAnswers = async () => {
    try {
      const response = await fetch(
        `/api/v1/list/answer/public/${user_id}`
      );
      const data: Answer[] = await response.json();
      setAnswers(data);
    } catch (error) {
      console.error('Error fetching answers:', error);
    }
  };

  const UTCtoJST = (utcDate: Date): string => {
    const jstDate = moment.utc(utcDate).tz('Asia/Tokyo');
    const formattedDate = jstDate.format('YYYY-MM-DD HH:mm:ss');
    return formattedDate;
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(
          `/api/v1/question/${user_id}`
        );
  
        if (!response.ok) {
          if (response.status === 404) {
            setErrorMessage('ユーザーはまだ登録していないか現在質問を受け付けていません');
          } else {
            console.error('Request failed with status:', response.status);
            const errorData = await response.json();
            setErrorMessage(errorData.error);
          }
        } else {
          const data = await response.json();
          setUserInfo(data);
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
        setErrorMessage(
          'ユーザーはまだ登録していないか現在質問を受け付けていません'
        );
        throw new Error("ユーザーはまだ登録していないか現在質問を受け付けていません");
      }
    };
  
    fetchUserInfo();
    fetchAnswers();
  }, [user_id]);  

  const handleCloseSnackbar = () => {
    setQuestionSubmitted(false);
    setQuestionSubmitError(false);
    setQuestionApiLimitError(false);
    setCFTurnstileToken(false);
  };

  const handleNsfwChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setNsfwDisplay(newValue)
  }

  const handlenotAnonymousCange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setnotAnonymous(newValue)
  }

  const enterPost = (keyEvent:any) => {
    if (keyEvent.key === 'Enter' && (keyEvent.ctrlKey || keyEvent.metaKey)) {
      handleSubmitClick()
    }
  }

  return (
    <div>
      {errorMessage && (<p>{errorMessage}</p>)}
      {userInfo && (
        <div>
          <Alert severity="error" sx={{mb:4}}>
              誹謗中傷は利用の<a href="https://gist.github.com/YoheiZuho/f08a8a9a1b79b794c843996739060408#file-fediqbrule-md">ルール</a>で禁止されています。<a href="https://gist.github.com/YoheiZuho/f08a8a9a1b79b794c843996739060408#file-fediqbrule-md">ルール</a>を守って質問しましょう。<br />
          </Alert>
          <Grid container alignItems='center' justifyContent='center' direction='column'>
            <Avatar alt={userInfo.display_name} src={userInfo.avatar} sx={{ width: 150, height: 150 }} />
            <Typography variant='h5' component='h5' sx={{ mt: 2, wordBreak: "break-all" }} align="center" >
              {userInfo.display_name}
            </Typography>
            <Typography>
              さんの質問箱
            </Typography>
            <Typography sx={{ mt: 0, wordBreak: "break-all" }} align="center">
              <a rel="me" href={'https://' + userInfo.server + '/@' + userInfo.user_id}>
                @{userInfo.user_id}@{userInfo.server}
              </a>
            </Typography>
            <TextField
              id='outlined-multiline-static'
              label='質問する'
              fullWidth
              multiline
              rows={4}
              value={Question}
              onChange={handleQuestionChange}
              sx={{ mt: 5, maxWidth: 800  }}
              onKeyDown={enterPost}
            />

            <Typography style={{ marginLeft: '5px' }} sx={{ mt: 1 }}>文字数: {Question.length}/80</Typography>
            {jwtToken ? (
              <>
                <Badge badgeContent={"new"} color="warning">
                  <FormGroup>
                    <FormControlLabel control={<Switch defaultChecked={notAnonymous} onChange={handlenotAnonymousCange} />} label="質問者のIDを相手に表示する" />
                  </FormGroup>
                </Badge>
                {userInfo.loginonly ? (
                  <FormGroup>
                    <Turnstile setToken={setToken} onReset={questionApiLimitError || questionSubmitError || questionSubmitted} />
                    <Button
                      variant='contained'
                      sx={{ mt: 1}}
                      onClick={handleSubmitClick}
                      disabled={Question.length === 0 || Question.length > 80 || !!error}
                    >
                      質問する
                    </Button>
                  </FormGroup>
                ) : (
                  <FormGroup>
                    <Turnstile setToken={setToken} onReset={questionApiLimitError || questionSubmitError || questionSubmitted} />
                    <Button
                      variant='contained'
                      sx={{ mt: 1 }}
                      onClick={handleSubmitClick}
                      disabled={Question.length === 0 || Question.length > 80 || !!error}
                    >
                      質問する
                    </Button>
                  </FormGroup>
                )}
              </>
            ) : (
              <>
                <FormGroup>
                  <FormControlLabel control={<Switch defaultChecked={notAnonymous} onChange={handlenotAnonymousCange} />} disabled label="ログインすると質問者に自分のIDを表示させることができます。" />
                </FormGroup>
                {userInfo.loginonly ? (
                  <FormGroup>
                    <Button
                      variant='contained'
                      sx={{ mt: 1 }}
                      onClick={handleSubmitClick}
                      disabled={true}
                    >
                      このユーザーはログインしているユーザーのみから質問を受け付けています
                    </Button>
                  </FormGroup>
                ) : (
                  <FormGroup>
                    <Turnstile setToken={setToken} onReset={questionApiLimitError || questionSubmitError || questionSubmitted} />
                    <Button
                      variant='contained'
                      sx={{ mt: 1 }}
                      onClick={handleSubmitClick}
                      disabled={Question.length === 0 || Question.length > 80 || !!error}
                    >
                      質問する
                    </Button>
                  </FormGroup>
                )}
              </>
            )}

            {error && <Typography style={{ color: 'red' }}>{error}</Typography>}

          </Grid>
          <Typography variant='h5' component='h5' sx={{ mt: 2 }}>
            {userInfo.display_name}の最近の回答
          </Typography>
          <FormGroup>
            <FormControlLabel control={<Switch defaultChecked={nsfwDisplay} onChange={handleNsfwChange} />} label="NSFW指定された質問を表示する" />
          </FormGroup>
          <List>
          {answers.map((answer) => (
            // nsfwDisplay が true または answer.nsfw が false の場合に Card を表示
            (nsfwDisplay || !answer.nsfw) && (
              <Card key={answer.id} sx={{ minWidth: 275, mt: 1.5 }}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    質問
                  </Typography>
                  <Typography variant="h6" component="div" sx={{ wordBreak: "break-all" }}>
                    {answer.question}
                  </Typography>
                  <Typography sx={{ mt: 1.5, fontSize: 14 }} color="text.secondary">
                    回答
                  </Typography>
                  <Typography>
                    {answer.answer}
                  </Typography>
                  <Grid container>
                    <Typography variant="body2" sx={{ mt: 1.5, mr: 1.2 }} color="text.secondary">
                      質問者：
                      {answer.not_anonymous ? (
                        <a href={'https://' + answer.questioner_server + '/@' + answer.questioner_user_id}>
                          @{answer.questioner_user_id}@{answer.questioner_server}
                        </a>
                      ):(
                        "匿名の質問者"
                      )}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1.5 }} color="text.secondary">
                      回答日時：{UTCtoJST(answer.answerd_at)}
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            )
          ))}
          </List>
        </div>
      )}
      <Snackbar
        open={questionSubmitted}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message='質問されました！'
      />
      <Snackbar
        open={questionSubmitError}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message='サーバーエラーが発生しました。しばらくしてから再度ためしてください。'
      />
      <Snackbar
        open={questionApiLimitError}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message='APIリミットに達しました。しばらくしてから再度ためしてください。'
      />
      <Snackbar
        open={CFTurnstileToken}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message='人間であることを証明してください。'
      />
    </div>
  );
};

export default QuestionBox;
