import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Router } from './Router';
import DrawerAppBar from './components/header';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

function App() {
  return (
    <BrowserRouter>
      <Box sx={{ display: 'flex' }}>
        <DrawerAppBar/>
        <Container>
          <Box component="main" sx={{ pt: 10 }}>
            <Router/>
          </Box>
        </Container>
      </Box>
    </BrowserRouter>
  );
}

export default App;