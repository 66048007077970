import React, { useEffect, useRef } from 'react';

interface TurnstileProps {
  setToken: (token: string | null) => void;
  onReset: boolean;
}

declare global {
  interface Window {
    turnstile: any;
  }
}

const Turnstile: React.FC<TurnstileProps> = ({ setToken, onReset }) => {
  const widgetIdRef = useRef<string | null>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
    script.async = true;
    script.onload = () => {
      widgetIdRef.current = window.turnstile.render('#turnstile-container', {
        sitekey: '0x4AAAAAAAfB7Eo3vg_JSbBB',
        theme: 'light',
        callback: (token: string) => setToken(token),
      });
    };
    document.body.appendChild(script);

    return () => {
      if (widgetIdRef.current) {
        window.turnstile.remove(widgetIdRef.current);
      }
      document.body.removeChild(script);
    };
  }, [setToken]);

  useEffect(() => {
    if (onReset == false){
      return
    }
    handleReset()
  },[onReset])

  const handleReset = () => {
    if (widgetIdRef.current) {
      window.turnstile.reset(widgetIdRef.current);
    }
  };

  return (
    <div>
      <div id="turnstile-container"></div>
    </div>
  );
};

export default Turnstile;

