import React, { useEffect } from "react";
import "@idotj/mastodon-embed-timeline/dist/mastodon-timeline.min.css";
import * as MastodonTimeline from "@idotj/mastodon-embed-timeline";

interface MastodonEmbedProps {
  instanceUrl: string;
  timelineType?: "local" | "profile" | "hashtag";
  userId?: string;
  profileName?: string;
  hashtagName?: string;
  maxNbPostShow?: number;
  defaultTheme?: "light" | "dark" | "auto";
  btnSeeMore?: string;
  btnReload?: string;
  hidePinnedPosts?: boolean;
}

const MastodonEmbed: React.FC<MastodonEmbedProps> = ({
  instanceUrl,
  timelineType = "local",
  userId,
  profileName,
  hashtagName,
  maxNbPostShow = 20,
  defaultTheme = "auto",
  btnSeeMore = "もっと見る",
  btnReload = "更新",
  hidePinnedPosts = true,
}) => {
  useEffect(() => {
    const options = {
      instanceUrl,
      timelineType,
      userId,
      profileName,
      hashtagName,
      maxNbPostShow,
      defaultTheme,
      btnSeeMore,
      btnReload,
      hidePinnedPosts,
    };

    const myTimeline = new MastodonTimeline.Init(options);

    return () => {
      // Cleanup if necessary
    };
  }, [instanceUrl, timelineType, userId, profileName, hashtagName, maxNbPostShow, defaultTheme]);

  return (
    <div id="mt-container" className="mt-container">
      <div className="mt-body" role="feed">
        <div className="mt-loading-spinner"></div>
      </div>
    </div>
  );
};

export default MastodonEmbed;
