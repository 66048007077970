import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    Grid,
    Snackbar,
    Typography,
} from '@mui/material';

const Login = () => {
    const [serverMastodonName, setMastodonServerName] = useState('');
    const [serverMisskeyName, setMisskeyServerName] = useState('');
    const [loginUrl, setLoginUrl] = useState('');
    const [errorOccured, setErrorOccured] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleMastodonServerNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setMastodonServerName(value);
        if (value.includes('@')) {
            setErrorMessage('「@」を入れずにドメイン名だけを入力してください。（例：m.zuiho.moe）');
        } else {
            setErrorMessage(null);
        }
    };

    const handleMisskeyServerNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setMisskeyServerName(value);
        if (value.includes('@')) {
            setErrorMessage('「@」を入れずにドメイン名だけを入力してください。（例：m.zuiho.moe）');
        } else {
            setErrorMessage(null);
        }
    };

    const handleMastodonLoginClick = async () => {
        try {
            const response = await fetch(`/api/v1/login/mastodon/${serverMastodonName}`);
            const data = await response.json();
            setLoginUrl(data.login_url);
            if (response.status === 400 || response.status === 500) {
                setErrorOccured(true);
            }
        } catch (error) {
            console.error('Error fetching login URL:', error);
            setErrorOccured(true);
        }
    };

    const handleMisskeyLoginClick = async () => {
        try {
            const response = await fetch(`/api/v1/login/misskey/${serverMisskeyName}`);
            const data = await response.json();
            setLoginUrl(data.login_url);
            if (response.status === 400 || response.status === 500) {
                setErrorOccured(true);
            }
        } catch (error) {
            console.error('Error fetching login URL:', error);
            setErrorOccured(true);
        }
    };

    const enterMastodonLogin = (keyEvent:any) => {
        if (keyEvent.key === 'Enter' && (keyEvent.ctrlKey || keyEvent.metaKey)) {
          handleMastodonLoginClick()
        }
    }

    const enterMisskeyLogin = (keyEvent:any) => {
        if (keyEvent.key === 'Enter' && (keyEvent.ctrlKey || keyEvent.metaKey)) {
          handleMisskeyLoginClick()
        }
    }

    useEffect(() => {
        if (loginUrl) {
            window.location.href = loginUrl;
        }
    }, [loginUrl]);

    const handleCloseSnackbar = () => {
        setErrorOccured(false);
    };

    return (
        <div>
            <Grid container alignItems='center' justifyContent='center' direction="column">
                <h1>Mastodonでログイン</h1>
                <p>Mastodonのサーバードメイン名を入力してください</p>
                <TextField
                    id="outlined-basic"
                    size="small"
                    label="サーバー名"
                    placeholder="mstdn.y-zu.org"
                    variant="outlined"
                    value={serverMastodonName}
                    onChange={handleMastodonServerNameChange}
                    onKeyDown={enterMastodonLogin}
                />
                {errorMessage && <Typography style={{ color: 'red' }}>{errorMessage}</Typography>}
                <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    onClick={handleMastodonLoginClick}
                    disabled={serverMastodonName.length === 0 || !!errorMessage}
                >
                    ログイン
                </Button>
            </Grid>
            <Grid sx={{ mt: 5 }} container alignItems='center' justifyContent='center' direction="column">
                <h1>Misskeyでログイン</h1>
                <p>Misskeyのサーバードメイン名を入力してください</p>
                <TextField
                    id="outlined-basic"
                    size="small"
                    label="サーバー名"
                    placeholder="m.zuiho.moe"
                    variant="outlined"
                    value={serverMisskeyName}
                    onChange={handleMisskeyServerNameChange}
                    onKeyDown={enterMisskeyLogin}
                />
                {errorMessage && <Typography style={{ color: 'red' }}>{errorMessage}</Typography>}
                <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    onClick={handleMisskeyLoginClick}
                    disabled={serverMisskeyName.length === 0 || !!errorMessage}
                >
                    ログイン
                </Button>
            </Grid>
            <Snackbar
                open={errorOccured}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message='サーバーが存在しないかサーバーエラーが起きています。'
            />
        </div>
    );
}

export default Login;